<template lang="pug">
div
  .navbar
    a(href='javascript:void(0);' @click='downLoadPdf')
      img(src='@/assets/images/download.png' style='width: 30px')
  .invoice-info
    div
      .invoice-content
        .flex(style='justify-content: space-between')
          img(src='@/assets/images/logo.png' style='width: 300px; margin-left: 25px')
          div
            //- img#image-tia(v-if="(invoiceBase.ticUrl && payment.balance == 0 && payment.printTIA === 'N') || (payment.ticUrl && payment.printTIA === 'Y')" crossorigin='anonymous' :src="payment.ticUrl" style='width: 330px; padding-right: 20px')
            img#image-tia(v-if="invoiceBase.ticUrl" crossorigin='anonymous' :src="ticBase64Url" style='width: 400px; padding-right: 20px')
            div(style='height: 45px;')
              //- div(v-if="(invoiceBase.ticUrl && payment.balance == 0 && payment.printTIA === 'N') || (payment.ticUrl && payment.printTIA === 'Y')" style='float: right; font-size: 10px; padding-right: 35px')
              div(v-if="invoiceBase.ticUrl" style='float: left; font-size: 10px; font-weight:500')
                div 「&#x65C5;&#x5BA2;&#x5FC5;&#x9808;&#x53D6;&#x5F97;&#x5370;&#x82B1;&#x6536;&#x64DA;&#xFF0C;&#x65B9;&#x53EF;&#x7372;&#x5F97;&#x300E;&#x65C5;&#x904A;&#x696D;&#x8CE0;&#x511F;&#x57FA;&#x91D1;&#x300F;&#x4FDD;&#x969C;&#x3002;
                div
                  | Travellers must obtain receipts with levy stamps to have
                  | protection by the
                div Travel Industry Compensation Fund.」
        .official-receipt Official Receipt&#x6B63;&#x5F0F;&#x6536;&#x64DA;
        .div-receipt
          .width-50.pl25
            .flex
              .width125 To &#x806F;&#x7D61;&#x4EBA;
              .width30 :
              div {{payment.receiptOrderInfoVo.contact}}
            .flex
              .width125 Tel No. &#x96FB;&#x8A71;
              .width30 :
              div {{payment.receiptOrderInfoVo.contactPhone}}
            .flex
              .width125 Email &#x96FB;&#x90F5;
              .width30 :
              div {{payment.receiptOrderInfoVo.contactEmail}}
            .flex
              .width125 Issue Date &#x767C;&#x7968;&#x65E5;&#x671F;
              .width30 :
              div {{moment(payment.invoiceBaseVo.issueDate).format('DD MMM YYYY')}}
          .width-50.pl90
            .flex
              .width125 Booking No. &#x8A02;&#x55AE;&#x7DE8;&#x865F;
              .width30 :
              div {{payment.receiptOrderInfoVo.orderNumber}}
            .flex
              .width125 Receipt No. &#x6536;&#x64DA;&#x7DE8;&#x865F;
              .width30 :
              div {{payment.invoiceBaseVo.receiptNumber}}
            div(v-if="payment.invoiceBaseVo.salesman").flex
              .width125 Salesman &#x92B7;&#x552E;&#x4EBA;&#x54E1;
              .width30 :
              div {{payment.invoiceBaseVo.salesman}}
            div(v-if="payment.invoiceBaseVo.salesman").flex
              .width125 Branch &#x5206;&#x884C;
              .width30 :
              div {{payment.invoiceBaseVo.branch}}
        .font-weight-bold.font10(style='padding: 5px 25px')
          div
            | Thank you for ordering our company&apos;s tour group. The registration
            | details are as follows &#x591A;&#x8B1D;&#x95A3;&#x4E0B;&#x8A02;&#x8CFC;&#x672C;&#x516C;&#x53F8;&#x4E4B;&#x65C5;&#x884C;&#x5718;&#xFF0C;&#x5831;&#x540D;&#x8A73;&#x60C5;&#x5982;&#x4E0B;&#xFF1A;
          .flex
            div
              | Tour Code &#x65C5;&#x884C;&#x5718;&#x7DE8;&#x865F;: {{payment.packageCode}} {{payment.title}}
            div(style='padding: 0 70px')
              | Departure date &#x51FA;&#x767C;&#x65E5;&#x671F;: {{moment(payment.departureDate).format('DD MMM YYYY')}}
        div
          table.font10
            thead
              tr
                th.text-center(width='38%' colspan='3')
                  | Customer Name &#x9867;&#x5BA2;&#x59D3;&#x540D;
                  .flex
                    div(style='width: 8%') Title
                    div(style='width: 60%') English Name
                    div &#x4E2D;&#x6587;&#x59D3;&#x540D;
                th(width='10%')
                  | Date of Birth
                  div &#x51FA;&#x751F;&#x65E5;&#x671F;
                th(width='11%')
                  | Category
                  div &#x985E;&#x5225;
                th(width='20%')
                  | Type of Certificate
                  div &#x8B49;&#x4EF6;&#x985E;&#x5225;
                th(width='11%')
                  | Certificate No.
                  div &#x8B49;&#x4EF6;&#x865F;&#x78BC;
                th(width='10%')
                  | Expiry Date
                  div &#x6709;&#x6548;&#x671F;
            tbody
              tr(v-for="(item,index) in payment.members")
                td(style='width: 5%') {{item.titleEngName}}
                td(style='width: 20%') {{item.surnameEng ? item.surnameEng + " " + item.givenNameEng : ''}}
                td {{item.surnameChina + " " + (item.givenNameChina || '')}}
                td {{moment(item.birthday).format('DDMMMYYYY').toUpperCase().slice(0, -2) + '**'}}
                td {{(item.category || '') + " " + (item.categoryChina || '')}}
                td {{ certificateType(item.typeOfCertificate) }}
                //- case item.typeOfCertificate
                  when 'HKIC'
                    td 香港身份證
                  when 'HKSAR'
                    td 香港特區護照(SAR)
                  when 'HKDOI'
                    td 香港簽證身份書(D.I.)
                  when 'MSP'
                    td 澳門特區護照
                  when 'MTP'
                    td 澳門旅行證
                  when 'HRP'
                    td 回鄉證
                  when 'TP'
                    td 往來港澳通行証(個人旅遊G簽注)
                  when 'CP'
                    td 中國護照
                  when 'AU'
                    td 澳洲/澳大利亞
                  when 'CA'
                    td 加拿大
                  when 'CH'
                    td 瑞士
                  when 'DE'
                    td 德國
                  when 'FR'
                    td 法國
                  when 'UK'
                    td 英國
                  when 'ID'
                    td 印尼
                  when 'JP'
                    td 日本
                  when 'MY'
                    td 馬來西亞
                  when 'NL'
                    td 荷蘭
                  when 'PT'
                    td 葡萄牙
                  when 'SG'
                    td 新加坡
                  when 'TH'
                    td 泰國
                  when 'TW'
                    td 台灣
                  when 'US'
                    td 美國
                  when 'NZ'
                    td 紐西蘭
                  when 'OTHER'
                    td 其他
                  default
                    td {{item.typeOfCertificate}}
                td {{item.passportNumber ? item.passportNumber.slice(0, 4) : ''}}
                td.text-center {{item.dateOfExpiry ? moment(item.dateOfExpiry).format('DD-MM-YYYY') : ""}}
        template(v-if="payment.members.length <= 15")
          .font10.pl25.pt10.pb10 Remarks&#x5099;&#x8A3B;: {{payment.isCollectionAndSeat ? payment.collectionAndSeat + ' ' + payment.otherRemarks : payment.otherRemarks}}
          .font10.pl25.pr25
            | I have verified the names of the participating guests on the
            | receipt. There will be corrections in the future and I know that
            | administrative fees will be charged according to the terms of the
            | rules.
            div
              | &#x672C;&#x4EBA;&#x5DF2;&#x6838;&#x5BE6;&#x6536;&#x64DA;&#x4E0A;&#x4E4B;&#x53C3;&#x5718;&#x5609;&#x8CD3;&#x59D3;&#x540D;&#xFF0C;&#x65E5;&#x5F8C;&#x6709;&#x66F4;&#x6B63;&#xFF0C;&#x5DF2;&#x77E5;&#x6089;&#x5C07;&#x6309;&#x7D30;&#x5247;&#x689D;&#x6B3E;&#x6536;&#x53D6;&#x884C;&#x653F;&#x8CBB;&#x7528;&#x3002;&#x7C3D;&#x7F72;&#xFF1A;_______________________________________
            if(specialRemarks)
              .font-weight-bold.pt10 {{specialRemarks}}
          .font-weight-bold.pl25.pt10 Payment Records &#x4ED8;&#x6B3E;&#x8A18;&#x9304;
          table#tablePayment.font10
            thead
              tr
                th.pl10(width='40%') Item &#x9805;&#x76EE;
                th.pl10(width='13%') Unit Price &#x55AE;&#x50F9;
                th.pl10(width='13%') Qty. &#x6578;&#x91CF;
                th.pl10(width='34%') Amount &#x91D1;&#x984D;
            tbody
              tr
                td.pl10 Adult Tour Fee &#x6210;&#x4EBA;&#x5718;&#x8CBB;
                td.pl10 ${{payment.unitPriceAdult}}
                td.pl10 {{payment.unitPriceAdult ? payment.numberOfAdults : 0}}
                td.pl10 ${{payment.unitPriceAdult * payment.numberOfAdults}}
              tr
                td.pl10 Fuel Surcharge &#x71C3;&#x6CB9;&#x9644;&#x52A0;&#x8CBB;
                td.pl10 ${{payment.unitPriceFuelSurchargeAdult ? payment.unitPriceFuelSurchargeAdult : 0}}
                td.pl10 {{payment.unitPriceFuelSurchargeAdult ? payment.numberOfAdults : 0}}
                td.pl10 ${{(payment.unitPriceFuelSurchargeAdult ? payment.unitPriceFuelSurchargeAdult : 0) * payment.numberOfAdults}}
              tr
                td.pl10 Air Passenger Departure Tax &#x98DB;&#x6A5F;&#x4E58;&#x5BA2;&#x96E2;&#x5883;&#x7A05;
                td.pl10 ${{payment.unitPriceAirTaxAdult ? payment.unitPriceAirTaxAdult : 0}}
                td.pl10 {{payment.unitPriceAirTaxAdult ? payment.numberOfAdults : 0}}
                td.pl10 ${{(payment.unitPriceAirTaxAdult ? payment.unitPriceAirTaxAdult : 0) * payment.numberOfAdults}}
              tr
                td.pl10
                  | Air Passenger Security Charge &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
                td.pl10 ${{payment.unitPriceSecurityChargeAdult ? payment.unitPriceSecurityChargeAdult : 0}}
                td.pl10 {{payment.unitPriceSecurityChargeAdult ? payment.numberOfAdults : 0}}
                td.pl10 ${{(payment.unitPriceSecurityChargeAdult ? payment.unitPriceSecurityChargeAdult : 0) * payment.numberOfAdults}}
              tr
                td.pl10
                  | Port Tax &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
                td.pl10 ${{payment.unitPricePortTaxAdult ? payment.unitPricePortTaxAdult : 0}}
                td.pl10 {{payment.unitPricePortTaxAdult ? payment.numberOfAdults : 0}}
                td.pl10 ${{(payment.unitPricePortTaxAdult ? payment.unitPricePortTaxAdult : 0) * payment.numberOfAdults}}
              tr
                td.pl10 Insurance Fee &#x4FDD;&#x96AA;&#x8CBB;
                td.pl10 ${{payment.adultInsuranceFee}}
                //- td.pl10 {{payment.adultInsuranceFee ? payment.numberOfAdultsInsurance : 0}}
                //- td.pl10 ${{payment.adultInsuranceFee * payment.numberOfAdultsInsurance}}
                td.pl10 {{payment.numberOfAdults ? payment.numberOfAdults : 0}}
                td.pl10 ${{payment.numberOfAdultsInsuranceFee }}
              tr
                td.pl10 Children Tour Fee &#x5152;&#x7AE5;&#x5718;&#x8CBB;
                td.pl10 ${{payment.unitPriceChildren}}
                td.pl10 {{payment.unitPriceChildren ? payment.numberOfChildren : 0}}
                td.pl10 ${{payment.unitPriceChildren * payment.numberOfChildren}}
              tr
                td.pl10 Fuel Surcharge &#x71C3;&#x6CB9;&#x9644;&#x52A0;&#x8CBB;
                td.pl10 ${{payment.unitPriceFuelSurchargeChild ? payment.unitPriceFuelSurchargeChild : 0}}
                td.pl10 {{payment.unitPriceFuelSurchargeChild ? payment.numberOfChildren :  0}}
                td.pl10 ${{(payment.unitPriceFuelSurchargeChild ? payment.unitPriceFuelSurchargeChild : 0) * (payment.numberOfChildren)}}
              tr
                td.pl10 Air Passenger Departure Tax 小童團費
                td.pl10 ${{payment.unitPriceAirTaxChild ? payment.unitPriceAirTaxChild : 0}}
                td.pl10 {{payment.unitPriceAirTaxChild ? payment.numberOfChildren : 0}}
                td.pl10 ${{(payment.unitPriceAirTaxChild ? payment.unitPriceAirTaxChild : 0) * (payment.numberOfChildren)}}
              tr
                td.pl10
                  | Air Passenger Security Charge &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
                td.pl10 ${{payment.unitPriceSecurityChargeChild ? payment .unitPriceSecurityChargeChild : 0}}
                td.pl10 {{payment.unitPriceSecurityChargeChild ? payment.numberOfChildren : 0}}
                td.pl10 ${{(payment.unitPriceSecurityChargeChild ? payment .unitPriceSecurityChargeChild : 0) * (payment.numberOfChildren)}}
              tr
                td.pl10
                  | Port Tax &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
                td.pl10 ${{payment.unitPricePortTaxChild ? payment .unitPricePortTaxChild : 0}}
                td.pl10 {{payment.unitPricePortTaxChild ? payment.numberOfChildren : 0}}
                td.pl10 ${{(payment.unitPricePortTaxChild ? payment .unitPricePortTaxChild : 0) * (payment.numberOfChildren)}}
              tr
                td.pl10 Insurance Fee &#x4FDD;&#x96AA;&#x8CBB;
                td.pl10 ${{payment.childrenInsuranceFee}}
                //- td.pl10 {{payment.childrenInsuranceFee ? payment.numberOfChildrenInsurance : 0}}
                //- td.pl10 ${{(payment.childrenInsuranceFee ? payment.childrenInsuranceFee : 0) * (payment.numberOfChildrenInsurance)}}
                td.pl10 {{payment.numberOfChildren ? payment.numberOfChildren : 0}}
                td.pl10 ${{(payment.numberOfChildrenInsuranceFee)}}
              tr
                td.pl10 Infant Tour Fee&#x5B30;&#x5152;&#x5718;&#x8CBB;
                td.pl10 ${{payment.unitPriceBaby}}
                td.pl10 {{payment.unitPriceBaby ? payment.numberOfBabies : 0}}
                td.pl10 ${{payment.unitPriceBaby * payment.numberOfBabies}}
              tr
                td.pl10 Insurance Fee &#x4FDD;&#x96AA;&#x8CBB;
                td.pl10 ${{payment.babyInsuranceFee}}
                //- td.pl10 {{payment.numberOfBabiesInsurance}}
                //- td.pl10 ${{payment.numberOfBabiesInsurance * payment.babyInsuranceFee}}
                td.pl10 {{payment.numberOfBabies}}
                td.pl10 ${{payment.numberOfBabiesInsuranceFee}}
              tr
                td.pl10 Single Room &#x55AE;&#x4EBA;&#x623F;&grave;&#x9644;&#x52A0;&#x8CBB;
                td.pl10 ${{payment.singleRoomPrice}}
                td.pl10 {{payment.numberOfSingleRoom}}
                td.pl10 ${{payment.singleRoomPrice * payment.numberOfSingleRoom}}
              tr
                td.pl10 Extra Bed &#x52A0;&#x5E8A;&#x9644;&#x52A0;&#x8CBB;
                td.pl10 ${{payment.extraBedPrice}}
                td.pl10 {{payment.numberOfExtraBedRoom}}
                td.pl10 ${{payment.extraBedPrice * payment.numberOfExtraBedRoom}}
              tr
                td.pl10 Children Occupy Bed 小童占床
                td.pl10 ${{payment.childrenBedPrice}}
                td.pl10 {{payment.numberOfChildrenOccupyRoom}}
                td.pl10 ${{payment.childrenBedPrice}}
              tr
                td.pl10 Tips 旅行团服务费
                td.pl10 ${{payment.tip}}
                td.pl10 {{payment.numberOfTips}}
                td.pl10 ${{payment.tip * payment.numberOfTips}}
              tr
                td.pl10 Others Price 其它费用
                td.pl10 ${{payment.otherPrice}}
                td.pl10 {{payment.numberOfOtherPrices}}
                td.pl10 ${{payment.otherPrice * payment.numberOfOtherPrices}}
              tr
                td.pl10 VISA Fee &#x7C3D;&#x8B49;&#x8CBB;
                td.pl10 ${{payment.visaFee}}
                td.pl10 {{payment.numberOfVisa}}
                td.pl10 ${{payment.visaFee * payment.numberOfVisa}}
              tr
                td.pl10 Member Discount &#x6703;&#x54E1;&#x6298;&#x6263;
                td.pl10 -${{payment.memberDiscount}}
                td.pl10 {{payment.numberOfMemberDiscount}}
                td.pl10 -${{payment.memberDiscount * payment.numberOfMemberDiscount}}
              tr
                td.pl10 Promotion Discount &#x512A;&#x60E0;&#x6298;&#x6263;
                td.pl10 -${{payment.promotionDiscount}}
                td.pl10 {{payment.numberOfPromotionDiscount}}
                td.pl10 -${{payment.promotionDiscount * payment.numberOfPromotionDiscount}}
              tr
                td.pl10 Special Discount 特別折扣
                td.pl10 -${{payment.specialDiscount}}
                td.pl10 {{payment.numberOfSpecialDiscount}}
                td.pl10 -${{payment.specialDiscount * payment.numberOfSpecialDiscount}}
              //- if payment.transferDiscount > 0
              tr(v-if="payment.transferDiscount>0")
                td.pl10 Transfer Discount轉團折扣
                td.pl10 -${{payment.transferDiscount}}
                td.pl10 1
                td.pl10 -${{payment.transferDiscount}}
              tr
                td.pl-40(colspan='3') Sub Total &#x5C0F;&#x8A08; :
                td.pl10 ${{(payment.grandTotal - payment.tiaLevyPaid).toLocaleString()}}
              tr
                td.pl-40(colspan='3') Deposit Payment &#x5DF2;&#x4ED8; :
                td.pl10 ${{payment.depositPayment}}
              tr
                td.pl-40(colspan='3') TIA Levy Paid &#x65C5;&#x904A;&#x5370;&#x82B1;&#x7A05; :
                td.pl10 ${{payment.tiaLevyPaid.toLocaleString()}}
              tr
                td.pl-40(colspan='3') Total Paid Amount &#x61C9;&#x4ED8;&#x91D1;&#x984D; :
                td.pl10 ${{(payment.grandTotal).toLocaleString()}}
              tr
                //- if payment.paymentMethod === 'alipay'
                //-   td.pl-40(colspan='3') Paid by ALIPAY :
                //- if payment.paymentMethod === 'alipayInApp'
                //-   td.pl-40(colspan='3') Paid by QR CODE - ALIPAY 二維碼付款 :
                //- if payment.paymentMethod === 'wechat'
                //-   td.pl-40(colspan='3') Paid by WECHAT :
                //- if payment.paymentMethod === 'wechatInApp'
                //-   td.pl-40(colspan='3') Paid by QR CODE - WECHAT 二維碼付款 :
                //- if payment.paymentMethod === 'card'
                //-   td.pl-40(colspan='3') Paid by CASH :
                //- if payment.paymentMethod === 'fps'
                //-   td.pl-40(colspan='3') Paid by Faster Payment System BOC轉數快 :
                //- if payment.paymentMethod === 'shop'
                //-   td.pl-40(colspan='3') Paid by CASH :
                //- if payment.paymentMethod === 'master'
                //-   td.pl-40(colspan='3') Paid by MASTER :
                //- if payment.paymentMethod === 'visa'
                //-   td.pl-40(colspan='3') Paid by VISA :
                //- if payment.paymentMethod === 'unionpay'
                //-   td.pl-40(colspan='3') Paid by UNIONPAY 銀聯信用卡 :
                //- if payment.paymentMethod === 'amex'
                //-   td.pl-40(colspan='3') Paid by AMEX :
                //- if payment.paymentMethod === 'cartes_bancaires'
                //-   td.pl-40(colspan='3') Paid by CARTES BANCAIRES :
                //- if payment.paymentMethod === 'diners'
                //-   td.pl-40(colspan='3') Paid by DINERS :
                //- if payment.paymentMethod === 'discover'
                //-   td.pl-40(colspan='3') Paid by DISCOVER :
                //- if payment.paymentMethod === 'jcb'
                //-   td.pl-40(colspan='3') Paid by JCB :
                //- if payment.paymentMethod === 'cheque'
                //-   td.pl-40(colspan='3') Paid by CHEQUE :
                //- if payment.paymentMethod === 'octopus'
                //-   td.pl-40(colspan='3') Paid by OCTOPUS :
                //- if payment.paymentMethod === 'bih'
                //-   td.pl-40(colspan='3') Paid by BANK IN HSBC :
                //- if payment.paymentMethod === 'bib'
                //-   td.pl-40(colspan='3') Paid by BANK IN BOC :
                //- if payment.paymentMethod === 'cashCoupon'
                //-   td.pl-40(colspan='3') Paid by CASH COUPON :
                //- if payment.paymentMethod === 'eps/pps'
                //-   td.pl-40(colspan='3') Paid by EPS/PPS :
                //- if payment.paymentMethod === 'groupBuyer'
                //-   td.pl-40(colspan='3') Paid by Groupbuyer 團購:
                //- if payment.paymentMethod === 'inTrans'
                //-   td.pl-40(colspan='3') Paid by Internal Transfer:
                td.pl-40(colspan='3') Paid by {{payMethod[payment.paymentMethod]}} :
                td.pl10 ${{(payment.amount).toLocaleString()}}
              tr
                td.pl-40(colspan='3') Balance Payment  &#x9918;&#x6B3E; :
                td.pl10 ${{payment.balancePayment}}
          .font10.pl25.pt10
            div
              | Non-refundable upon reservation confirmed. &#x8A02;&#x59A5;&#x5F8C;&#x6B3E;&#x9805;&#x5747;&#x4E0D;&#x7372;&#x767C;&#x9084;
            div
              | Customer needs to ensure that their passport and visa have
              | adequate validity. &#x9867;&#x5BA2;&#x9700;&#x81EA;&#x884C;&#x6AA2;&#x67E5;&#x8B77;&#x7167;&#x53CA;&#x7C3D;&#x8B49;&#x662F;&#x5426;&#x6709;&#x6548;
            div
              | If there is any problem, the customer needs to bear the
              | consequences and responsibilities. &#x5982;&#x6709;&#x4EFB;&#x4F55;&#x554F;&#x984C;, &#x8CAC;&#x4EFB;&#x81EA;&#x8CA0;.
          .text-center.font-weight-bold.pt10
            | - END OF OFFICAL RECEIPT -
          .font10.font-weight-bold.pl25
            | &#x5982;&#x6709;&#x4EFB;&#x4F55;&#x610F;&#x898B;&#x6216;&#x67E5;&#x8A62;&#xFF0C;&#x6B61;&#x8FCE;&#x81F4;&#x96FB;&#x6216;&#x96FB;&#x90F5;&#x8207;&#x6211;&#x5011;&#x5BA2;&#x6236;&#x5C08;&#x54E1;&#x806F;&#x7D61;
          .font10.font-weight-bold.pl25
            | If you have any suggestions or enquiries, please do not hesitate to
            | contact us by phone or email.
          p.font10.text-center.text-bottom
            | This is a 
            nobr computer-generated
            |  document. No signature is
            |             required
          .title-pages.font10.pr25
            | Page 1 of 2
        template(v-else) 
          .title-pages.font10.pr25
            | Page 1 of 3
    template(v-if="payment.members.length > 15")
      .invoice-content(style='border-top: 1px solid;margin-top:100px')
        .font10.pl25.pt10.pb10 Remarks&#x5099;&#x8A3B;: {{payment.isCollectionAndSeat ? payment.collectionAndSeat + ' ' + payment.otherRemarks : payment.otherRemarks}}
        .font10.pl25.pr25
          | I have verified the names of the participating guests on the
          | receipt. There will be corrections in the future and I know that
          | administrative fees will be charged according to the terms of the
          | rules.
          div
            | &#x672C;&#x4EBA;&#x5DF2;&#x6838;&#x5BE6;&#x6536;&#x64DA;&#x4E0A;&#x4E4B;&#x53C3;&#x5718;&#x5609;&#x8CD3;&#x59D3;&#x540D;&#xFF0C;&#x65E5;&#x5F8C;&#x6709;&#x66F4;&#x6B63;&#xFF0C;&#x5DF2;&#x77E5;&#x6089;&#x5C07;&#x6309;&#x7D30;&#x5247;&#x689D;&#x6B3E;&#x6536;&#x53D6;&#x884C;&#x653F;&#x8CBB;&#x7528;&#x3002;&#x7C3D;&#x7F72;&#xFF1A;_______________________________________
          if(specialRemarks)
            .font-weight-bold.pt10 {{specialRemarks}}
        .font-weight-bold.pl25.pt10 Payment Records &#x4ED8;&#x6B3E;&#x8A18;&#x9304;
        table#tablePayment.font10
          thead
            tr
              th.pl10(width='40%') Item &#x9805;&#x76EE;
              th.pl10(width='13%') Unit Price &#x55AE;&#x50F9;
              th.pl10(width='13%') Qty. &#x6578;&#x91CF;
              th.pl10(width='34%') Amount &#x91D1;&#x984D;
          tbody
            tr
              td.pl10 Adult Tour Fee &#x6210;&#x4EBA;&#x5718;&#x8CBB;
              td.pl10 ${{payment.unitPriceAdult}}
              td.pl10 {{payment.unitPriceAdult ? payment.numberOfAdults : 0}}
              td.pl10 ${{payment.unitPriceAdult * payment.numberOfAdults}}
            tr
              td.pl10 Fuel Surcharge &#x71C3;&#x6CB9;&#x9644;&#x52A0;&#x8CBB;
              td.pl10 ${{payment.unitPriceFuelSurchargeAdult ? payment.unitPriceFuelSurchargeAdult : 0}}
              td.pl10 {{payment.unitPriceFuelSurchargeAdult ? payment.numberOfAdults : 0}}
              td.pl10 ${{(payment.unitPriceFuelSurchargeAdult ? payment.unitPriceFuelSurchargeAdult : 0) * payment.numberOfAdults}}
            tr
              td.pl10 Air Passenger Departure Tax &#x98DB;&#x6A5F;&#x4E58;&#x5BA2;&#x96E2;&#x5883;&#x7A05;
              td.pl10 ${{payment.unitPriceAirTaxAdult ? payment.unitPriceAirTaxAdult : 0}}
              td.pl10 {{payment.unitPriceAirTaxAdult ? payment.numberOfAdults : 0}}
              td.pl10 ${{(payment.unitPriceAirTaxAdult ? payment.unitPriceAirTaxAdult : 0) * payment.numberOfAdults}}
            tr
              td.pl10
                  | Air Passenger Security Charge &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
              td.pl10 ${{payment.unitPriceSecurityChargeAdult ? payment.unitPriceSecurityChargeAdult : 0}}
              td.pl10 {{payment.unitPriceAirTaxAdult ? payment.numberOfAdults : 0}}
              td.pl10 ${{(payment.unitPriceSecurityChargeAdult ? payment.unitPriceSecurityChargeAdult : 0) * payment.numberOfAdults}}
            tr
              td.pl10
                  | Port Tax &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
              td.pl10 ${{payment.unitPricePortTaxAdult ? payment.unitPricePortTaxAdult : 0}}
              td.pl10 {{payment.unitPriceAirTaxAdult ? payment.numberOfAdults : 0}}
              td.pl10 ${{(payment.unitPricePortTaxAdult ? payment.unitPricePortTaxAdult : 0) * payment.numberOfAdults}}
            tr
              td.pl10 Insurance Fee &#x4FDD;&#x96AA;&#x8CBB;
              td.pl10 ${{payment.adultInsuranceFee}}
              td.pl10 {{payment.numberOfAdults ? payment.numberOfAdults : 0}}
              td.pl10 ${{payment.numberOfAdultsInsuranceFee}}
            tr
              td.pl10 Children Tour Fee &#x5152;&#x7AE5;&#x5718;&#x8CBB;
              td.pl10 ${{payment.unitPriceChildren}}
              td.pl10 {{payment.unitPriceChildren ? payment.numberOfChildren : 0}}
              td.pl10 ${{payment.unitPriceChildren * payment.numberOfChildren}}
            tr
              td.pl10 Fuel Surcharge &#x71C3;&#x6CB9;&#x9644;&#x52A0;&#x8CBB;
              td.pl10 ${{payment.unitPriceFuelSurchargeChild ? payment.unitPriceFuelSurchargeChild : 0}}
              td.pl10 {{payment.unitPriceChildren ? payment.numberOfChildren : 0}}
              td.pl10 ${{(payment.unitPriceFuelSurchargeChild ? payment.unitPriceFuelSurchargeChild : 0) * (payment.numberOfChildren)}}
            tr
              td.pl10 Air Passenger Departure Tax 小童團費
              td.pl10 ${{payment.unitPriceAirTaxChild ? payment.unitPriceAirTaxChild : 0}}
              td.pl10 {{payment.unitPriceChildren ? payment.numberOfChildren : 0}}
              td.pl10 ${{(payment.unitPriceAirTaxChild ? payment.unitPriceAirTaxChild : 0) * (payment.numberOfChildren)}}
            tr
              td.pl10
                  | Air Passenger Security Charge &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
              td.pl10 ${{payment.unitPriceSecurityChargeChild ? payment .unitPriceSecurityChargeChild : 0}}
              td.pl10 {{payment.unitPriceChildren ? payment.numberOfChildren : 0}}
              td.pl10 ${{(payment.unitPriceSecurityChargeChild ? payment .unitPriceSecurityChargeChild : 0) * (payment.numberOfChildren)}}
            tr
              td.pl10
                  | Port Tax &#x6A5F;&#x5834;&#x65C5;&#x5BA2;&#x4FDD;&#x5B89;&#x8CBB;
              td.pl10 ${{payment.unitPricePortTaxChild ? payment .unitPricePortTaxChild : 0}}
              td.pl10 {{payment.unitPriceChildren ? payment.numberOfChildren : 0}}
              td.pl10 ${{(payment.unitPricePortTaxChild ? payment .unitPricePortTaxChild : 0) * (payment.numberOfChildren)}}
            tr
              td.pl10 Insurance Fee &#x4FDD;&#x96AA;&#x8CBB;
              td.pl10 ${{payment.childrenInsuranceFee}}
              td.pl10 {{payment.numberOfChildren}}
              td.pl10 ${{payment.numberOfChildrenInsuranceFee }}
            tr
              td.pl10 Infant Tour Fee&#x5B30;&#x5152;&#x5718;&#x8CBB;
              td.pl10 ${{payment.unitPriceBaby}}
              td.pl10 {{payment.unitPriceBaby ? payment.numberOfBabies : 0}}
              td.pl10 ${{payment.unitPriceBaby * payment.numberOfBabies}}
            tr
              td.pl10 Insurance Fee &#x4FDD;&#x96AA;&#x8CBB;
              td.pl10 ${{payment.babyInsuranceFee}}
              td.pl10 {{payment.numberOfBabies}}
              td.pl10 ${{payment.numberOfBabiesInsuranceFee}}
            tr
              td.pl10 Single Room &#x55AE;&#x4EBA;&#x623F;&grave;&#x9644;&#x52A0;&#x8CBB;
              td.pl10 ${{payment.singleRoomPrice}}
              td.pl10 {{payment.numberOfSingleRoom}}
              td.pl10 ${{payment.singleRoomPrice * payment.numberOfSingleRoom}}
            tr
              td.pl10 Extra Bed &#x52A0;&#x5E8A;&#x9644;&#x52A0;&#x8CBB;
              td.pl10 ${{payment.extraBedPrice}}
              td.pl10 {{payment.numberOfExtraBedRoom}}
              td.pl10 ${{payment.extraBedPrice * payment.numberOfExtraBedRoom}}
            tr
              td.pl10 Children Occupy Bed 小童占床
              td.pl10 ${{payment.childrenBedPrice}}
              td.pl10 {{payment.numberOfChildrenOccupyRoom}}
              td.pl10 ${{payment.childrenBedPrice}}
            tr
              td.pl10 Tips 旅行团服务费
              td.pl10 ${{payment.tip}}
              td.pl10 {{payment.numberOfTips}}
              td.pl10 ${{payment.tip * payment.numberOfTips}}
            tr
              td.pl10 Others Price 其它费用
              td.pl10 ${{payment.otherPrice}}
              td.pl10 {{payment.numberOfOtherPrices}}
              td.pl10 ${{payment.otherPrice * payment.numberOfOtherPrices}}
            tr
              td.pl10 VISA Fee &#x7C3D;&#x8B49;&#x8CBB;
              td.pl10 ${{payment.visaFee}}
              td.pl10 {{payment.numberOfVisa}}
              td.pl10 ${{payment.visaFee * payment.numberOfVisa}}
            tr
              td.pl10 Member Discount &#x6703;&#x54E1;&#x6298;&#x6263;
              td.pl10 -${{payment.memberDiscount}}
              td.pl10 {{payment.numberOfMemberDiscount}}
              td.pl10 -${{payment.memberDiscount * payment.numberOfMemberDiscount}}
            tr
              td.pl10 Promotion Discount &#x512A;&#x60E0;&#x6298;&#x6263;
              td.pl10 -${{payment.promotionDiscount}}
              td.pl10 {{payment.numberOfPromotionDiscount}}
              td.pl10 -${{payment.promotionDiscount * payment.numberOfPromotionDiscount}}
            tr
              td.pl10 Special Discount 特別折扣
              td.pl10 -${{payment.specialDiscount}}
              td.pl10 {{payment.numberOfSpecialDiscount}}
              td.pl10 -${{payment.specialDiscount * payment.numberOfSpecialDiscount}}
            //- if payment.transferDiscount > 0
            tr(v-if="payment.transferDiscount > 0")
              td.pl10 Transfer Discount轉團折扣
              td.pl10 -${{payment.transferDiscount}}
              td.pl10 1
              td.pl10 -${{payment.transferDiscount}}
            tr
              td.pl-40(colspan='3') Sub Total &#x5C0F;&#x8A08; :
              td.pl10 ${{(payment.grandTotal - payment.tiaLevyPaid).toLocaleString()}}
            tr
              td.pl-40(colspan='3') Deposit Payment &#x5DF2;&#x4ED8; :
              td.pl10 ${{payment.depositPayment}}
            tr
              td.pl-40(colspan='3') TIA Levy Paid &#x65C5;&#x904A;&#x5370;&#x82B1;&#x7A05; :
              td.pl10 ${{payment.tiaLevyPaid.toLocaleString()}}
            tr
              td.pl-40(colspan='3') Total Paid Amount &#x61C9;&#x4ED8;&#x91D1;&#x984D; :
              td.pl10 ${{(payment.amount).toLocaleString()}}
            tr
              //- if payment.paymentMethod === 'alipay'
              //-   td.pl-40(colspan='3') Paid by ALIPAY :
              //- if payment.paymentMethod === 'alipayInApp'
              //-   td.pl-40(colspan='3') Paid by QR CODE - ALIPAY 二維碼付款 :
              //- if payment.paymentMethod === 'wechat'
              //-   td.pl-40(colspan='3') Paid by WECHAT :
              //- if payment.paymentMethod === 'wechatInApp'
              //-   td.pl-40(colspan='3') Paid by QR CODE - WECHAT 二維碼付款 :
              //- if payment.paymentMethod === 'card'
              //-   td.pl-40(colspan='3') Paid by CASH :
              //- if payment.paymentMethod === 'fps'
              //-   td.pl-40(colspan='3') Paid by Faster Payment System BOC轉數快 :
              //- if payment.paymentMethod === 'shop'
              //-   td.pl-40(colspan='3') Paid by CASH :
              //- if payment.paymentMethod === 'master'
              //-   td.pl-40(colspan='3') Paid by MASTER :
              //- if payment.paymentMethod === 'visa'
              //-   td.pl-40(colspan='3') Paid by VISA :
              //- if payment.paymentMethod === 'unionpay'
              //-   td.pl-40(colspan='3') Paid by UNIONPAY 銀聯信用卡 :
              //- if payment.paymentMethod === 'amex'
              //-   td.pl-40(colspan='3') Paid by AMEX :
              //- if payment.paymentMethod === 'cartes_bancaires'
              //-   td.pl-40(colspan='3') Paid by CARTES BANCAIRES :
              //- if payment.paymentMethod === 'diners'
              //-   td.pl-40(colspan='3') Paid by DINERS :
              //- if payment.paymentMethod === 'discover'
              //-   td.pl-40(colspan='3') Paid by DISCOVER :
              //- if payment.paymentMethod === 'jcb'
              //-   td.pl-40(colspan='3') Paid by JCB :
              //- if payment.paymentMethod === 'cheque'
              //-   td.pl-40(colspan='3') Paid by CHEQUE :
              //- if payment.paymentMethod === 'octopus'
              //-   td.pl-40(colspan='3') Paid by OCTOPUS :
              //- if payment.paymentMethod === 'bih'
              //-   td.pl-40(colspan='3') Paid by BANK IN HSBC :
              //- if payment.paymentMethod === 'bib'
              //-   td.pl-40(colspan='3') Paid by BANK IN BOC :
              //- if payment.paymentMethod === 'cashCoupon'
              //-   td.pl-40(colspan='3') Paid by CASH COUPON :
              //- if payment.paymentMethod === 'eps/pps'
              //-   td.pl-40(colspan='3') Paid by EPS/PPS :
              //- if payment.paymentMethod === 'groupBuyer'
              //-   td.pl-40(colspan='3') Paid by Groupbuyer 團購:
              //- if payment.paymentMethod === 'inTrans'
              //-   td.pl-40(colspan='3') Paid by Internal Transfer:
              td.pl-40(colspan='3') Paid by {{payMethod[payment.payMethod]}}:
              td.pl10 ${{(payment.amount).toLocaleString()}}
            tr
              td.pl-40(colspan='3') Balance Payment  &#x9918;&#x6B3E; :
              td.pl10 ${{payment.balancePayment}}
        .font10.pl25.pt10
          div
            | Non-refundable upon reservation confirmed. &#x8A02;&#x59A5;&#x5F8C;&#x6B3E;&#x9805;&#x5747;&#x4E0D;&#x7372;&#x767C;&#x9084;
          div
            | Customer needs to ensure that their passport and visa have
            | adequate validity. &#x9867;&#x5BA2;&#x9700;&#x81EA;&#x884C;&#x6AA2;&#x67E5;&#x8B77;&#x7167;&#x53CA;&#x7C3D;&#x8B49;&#x662F;&#x5426;&#x6709;&#x6548;
          div
            | If there is any problem, the customer needs to bear the
            | consequences and responsibilities. &#x5982;&#x6709;&#x4EFB;&#x4F55;&#x554F;&#x984C;, &#x8CAC;&#x4EFB;&#x81EA;&#x8CA0;.
        .text-center.font-weight-bold.pt10
          | - END OF OFFICAL RECEIPT -
        .font10.font-weight-bold.pl25
          | &#x5982;&#x6709;&#x4EFB;&#x4F55;&#x610F;&#x898B;&#x6216;&#x67E5;&#x8A62;&#xFF0C;&#x6B61;&#x8FCE;&#x81F4;&#x96FB;&#x6216;&#x96FB;&#x90F5;&#x8207;&#x6211;&#x5011;&#x5BA2;&#x6236;&#x5C08;&#x54E1;&#x806F;&#x7D61;
        .font10.font-weight-bold.pl25
          | If you have any suggestions or enquiries, please do not hesitate to
          | contact us by phone or email.
        p.font10.text-center.text-bottom
          | This is a 
          nobr computer-generated
          |  document. No signature is
          |             required
        .title-pages.font10.pr25
          | Page 2 of 3
    div
      .invoice-content
          .mt50(style='border-top: 1px solid')
            img(src='@/assets/images/receipt.jpg' style='width: 100%;')
          //- if(payment.members.length <= 15)
          div(v-if="payment.members.length <= 15").title-pages.font10.pr25
            | Page 2 of 2
          //- else
          div(v-else).title-pages.font10.pr25
            | Page 3 of 3

</template>
<script>
// import { defineProps, onMounted, ref } from 'vue';
import moment from 'moment';
import { downLoadPdf, convertImageToBase64 } from './downloadPdf.js';
export default {
  props: { payment: Object, invoiceBase: Object, ossPath: String },
  data() {
    return {
      certTypeMap: {
        HKIC: '香港身份證',
        HKSAR: '香港特區護照(SAR)',
        HKDOI: '香港簽證身份書(D.I.)',
        MSP: '澳門特區護照',
        MTP: '澳門旅行證',
        HRP: '回鄉證',
        TP: '往來港澳通行証(個人旅遊G簽注)',
        CP: '中國護照',
        AU: '澳洲/澳大利亞',
        CA: '加拿大',
        CH: '瑞士',
        DE: '德國',
        FR: '法國',
        UK: '英國',
        ID: '印尼',
        JP: '日本',
        MY: '馬來西亞',
        NL: '荷蘭',
        PT: '葡萄牙',
        SG: '新加坡',
        TH: '泰國',
        TW: '台灣',
        US: '美國',
        NZ: '紐西蘭',
        OTHER: '其他',
      },
      payMethod: {
        alipay: 'ALIPAY ',
        alipayInApp: 'QR CODE - ALIPAY 二維碼付款',
        wechat: 'WECHAT',
        wechatInApp: 'QR CODE - WECHAT 二維碼付款',
        card: 'CASH',
        fps: 'Faster Payment System BOC轉數快',
        shop: 'CASH',
        master: 'MASTER',
        visa: 'VISA',
        unionpay: 'UNIONPAY 銀聯信用卡',
        amex: 'AMEX',
        cartes_bancaires: 'CARTES BANCAIRES',
        diners: 'DINERS',
        discover: 'DISCOVER',
        jcb: 'JCB',
        cheque: 'CHEQUE',
        octopus: 'OCTOPUS',
        bih: 'BANK IN HSBC',
        bib: 'BANK IN BOC',
        cashCoupon: 'CASH COUPON',
        'eps/pps': 'EPS/PPS',
        groupBuyer: 'Groupbuyer 團購',
        inTrans: 'Internal Transfer',
      },
      ticBase64Url: '',
      moment,
    };
  },
  mounted() {
    console.log(this.invoiceBase, 4444);
    if (this.invoiceBase.ticUrl)
      convertImageToBase64(this.ossPath + this.invoiceBase.ticUrl).then(
        (res) => {
          this.ticBase64Url = res;
        },
      );
  },
  methods: {
    certificateType(type) {
      return this.certTypeMap[type] || type;
    },
    downLoadPdf,
  },
};
// const props = defineProps({
//   payment: Object,
//   invoiceBase: Object,
//   ossPath: String,
// });
// const certTypeMap = {
//   HKIC: '香港身份證',
//   HKSAR: '香港特區護照(SAR)',
//   HKDOI: '香港簽證身份書(D.I.)',
//   MSP: '澳門特區護照',
//   MTP: '澳門旅行證',
//   HRP: '回鄉證',
//   TP: '往來港澳通行証(個人旅遊G簽注)',
//   CP: '中國護照',
//   AU: '澳洲/澳大利亞',
//   CA: '加拿大',
//   CH: '瑞士',
//   DE: '德國',
//   FR: '法國',
//   UK: '英國',
//   ID: '印尼',
//   JP: '日本',
//   MY: '馬來西亞',
//   NL: '荷蘭',
//   PT: '葡萄牙',
//   SG: '新加坡',
//   TH: '泰國',
//   TW: '台灣',
//   US: '美國',
//   NZ: '紐西蘭',
//   OTHER: '其他',
// };
// const payMethod = {
//   alipay: 'ALIPAY ',
//   alipayInApp: 'QR CODE - ALIPAY 二維碼付款',
//   wechat: 'WECHAT',
//   wechatInApp: 'QR CODE - WECHAT 二維碼付款',
//   card: 'CASH',
//   fps: 'Faster Payment System BOC轉數快',
//   shop: 'CASH',
//   master: 'MASTER',
//   visa: 'VISA',
//   unionpay: 'UNIONPAY 銀聯信用卡',
//   amex: 'AMEX',
//   cartes_bancaires: 'CARTES BANCAIRES',
//   diners: 'DINERS',
//   discover: 'DISCOVER',
//   jcb: 'JCB',
//   cheque: 'CHEQUE',
//   octopus: 'OCTOPUS',
//   bih: 'BANK IN HSBC',
//   bib: 'BANK IN BOC',
//   cashCoupon: 'CASH COUPON',
//   'eps/pps': 'EPS/PPS',
//   groupBuyer: 'Groupbuyer 團購',
//   inTrans: 'Internal Transfer',
// };
// const ticBase64Url = ref('');
// const certificateType = (type) => {
//   return certTypeMap[type] || type;
// };
// onMounted(() => {
//   if (props.invoiceBase?.ticUrl)
//     convertImageToBase64(props.ossPath + props.invoiceBase.ticUrl).then(
//       (res) => {
//         ticBase64Url.value = res;
//       },
//     );
// });
</script>
