// $(document).ready(function () {
//   document.getElementById("image-tia").src = document
//     .getElementById("image-tia")
//     .src.replace("https://", "https://admin.");
// });

export function downLoadPdf() {
  const HTML_Width = $(".invoice-info").width();
  const HTML_Height = $(".invoice-info").height();
  const top_left_margin = 15;
  const PDF_Width = HTML_Width + top_left_margin * 2;
  const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
  const canvas_image_width = HTML_Width;
  const canvas_image_height = HTML_Height;

  const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

  html2canvas($(".invoice-info")[0], {
    allowTaint: true,
    quality: 4,
    scale: 5,
  }).then(function (canvas) {
    canvas.getContext("2d");
    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
    pdf.addImage(
      imgData,
      "JPG",
      top_left_margin,
      top_left_margin,
      canvas_image_width,
      canvas_image_height
    );

    for (let i = 1; i <= totalPDFPages; i++) {
      pdf.addPage(PDF_Width, PDF_Height);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        -(PDF_Height * i) + top_left_margin * 4,
        canvas_image_width,
        canvas_image_height
      );
    }

    pdf.save("Invoice.pdf");
  });
}

export function convertImageToBase64(url) {
  return new Promise((resolve, reject) => {
    // 创建一个XMLHttpRequest对象
    const xhr = new XMLHttpRequest();

    // 当请求完成时触发load事件
    xhr.onload = function () {
      if (xhr.status === 200) {
        // 创建一个图片对象
        const img = new Image();

        // 当图片加载完成时触发load事件
        img.onload = function () {
          // 创建一个Canvas元素
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // 设置Canvas的宽高和图片尺寸一致
          canvas.width = img.width;
          canvas.height = img.height;

          // 在Canvas上绘制图片
          ctx.drawImage(img, 0, 0);

          // 将Canvas转换为Base64格式的字符串
          const dataURL = canvas.toDataURL("image/png");

          // 返回Base64字符串
          resolve(dataURL);
        };

        // 设置图片源
        img.src = URL.createObjectURL(xhr.response);
      } else {
        reject(new Error(xhr.statusText)); // 处理请求错误
      }
    };

    // 发起GET请求
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.send();
  });
}
